import React from 'react'
import { FaCalendar } from "react-icons/fa/";
import { FaUser } from "react-icons/fa/";
import Share from '../components/share'

function canUseWebP() {
    const elem = typeof document === 'object' ? document.createElement('canvas') : {};

    if (!!(elem.getContext && elem.getContext('2d'))) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    } else {
        // very old browser like IE 8, canvas not supported
        return false;
    }
}

const Banner = (props) => {
    const webpSupport = canUseWebP();
    let imageUrl
    if(webpSupport===true){
        imageUrl = props.bgImage.srcWebp
    }
    else{
        imageUrl = props.bgImage.src
    }
    return (
    <section id="banner" className="major" style={{color:'white',backgroundImage:`url("${imageUrl}")`}}>
        <div className="inner">
            <header className="major">
                <h1 style={{color:'#ffffff'}}>{props.title}</h1>
            </header>
            <div className="content">
                <p>{props.description}</p>
                
                {/*<ul className="actions">
                    <li><a href="#one" className="button next scrolly">Get Started</a></li>
                </ul>*/}
            </div>
            <div className="content">
            <ul style={{listStyle:'none',marginLeft:'-1.5em',fontSize:'0.8em'}}>
                <li style={{display:'inline-block'}}><FaUser size={12} /> {props.author}</li>
                <li style={{display:'inline-block'}}><FaCalendar size={12} /> {props.date}</li>
            </ul>
            </div>

            <span style={{margin:'0 0 20px -10px',display:'flex'}}>
            <Share 
            url={props.url}
            title={props.title}
            image={props.image}
            twitterUsername={props.twitterUsername}
            hashTag={`${props.hashTag}`}
            /> 
            </span>
        </div>
    </section>)
}

export default Banner
