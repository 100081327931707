import React from 'react'
import {
    FacebookShareButton,
    PinterestShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon,
    PinterestIcon,
} from "react-share"


const Share = (props) => {
    //console.log(props.hashTag)
    return (
        <div className="share">
            <div className="SocialLinks">  
                <PinterestShareButton
                url={props.url}
                description={props.title}
                media={props.image}
                ><PinterestIcon round size={32} />
                </PinterestShareButton>

                <FacebookShareButton
                url={props.url}
                quote={props.title}
                hashtag={`#${props.hashTag}`}
                ><FacebookIcon round size={32} />
                </FacebookShareButton>

                <TwitterShareButton
                url={props.url}
                title={props.title}
                via={props.twitterUsername}
                hashtags={[props.hashTag]}
                ><TwitterIcon round size={32} />
                </TwitterShareButton>
            </div>
        </div> 
    )
}

export default Share