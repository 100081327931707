import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

//import PropTypes from 'prop-types'
import { Link } from 'gatsby'



const PageLink = props => {
    if(!props.active){
        return <Link to={props.url} className={props.className}>{props.text}</Link>
    }
    else{
        return <span className="button small disabled">{props.text}</span>
    }
}

function generateSmartLinks(links,index,pageCount){
    //let links = 3 //how many links u wanna display
    let start = index - links > 0 ? index - links : 1
    let end = index + links < pageCount ? index + links : pageCount 
    //let paging = ''
    const paging = []

    
    if(index===1)
        paging.push(<span className="button small disabled">&laquo;</span>)
    else
        paging.push(<Link to={`/articles/`} className='button small'>&laquo;</Link>)

    if(start>1){
        paging.push(<Link to={`/articles/`} className='button small'>1</Link>)
        paging.push(<span className="button small disabled">...</span>)
    }

    let i = start
    for(i=start; i<=end; i++){
        if(index !== i){
            if(i===1)
                paging.push(<Link to={`/articles/`} className='page'>{i}</Link>)
            else 
                paging.push(<Link to={`/articles/${i}`} className='page'>{i}</Link>)
        }
        else
            paging.push(<span className="button small disabled">{i}</span>)
    }

    if(end<pageCount){
        paging.push(<span>...</span>)
        paging.push(<Link to={`/articles/${pageCount}`} className='button small'>{pageCount}</Link>)
    }

    if(index===pageCount)
        paging.push(<span className="button small disabled">&raquo;</span>)
    else
        paging.push(<Link to={`/articles/${pageCount}`} className='button small'>&raquo;</Link>)
        
    
    return paging
}
const ArticlesIndex = ({pageContext}) =>{

        const { group, index, first, last, pageCount } = pageContext
        const previousUrl = index - 1 == 1 ? '' : (index - 1).toString()
        const nextUrl = (index + 1).toString()

        
        
        


        return (
            <Layout>
                <Helmet
                    title="Historophile - Articles"
                    meta={[
                        { name: 'description', content: '' },
                        { name: 'keywords', content: ', , ' },
                    ]}
                >
                </Helmet>

                {/*<Banner />*/}

                <div id="main" className="alt">
                    <section id="one">
                        <div className="inner">
                            <header className="major">
                                <h1>Articles</h1>
                            </header>


{group.map(({ node }) => (
<div key={node.id} className="blogListing">
<div className="date">{node.frontmatter.date}</div>
<Link className="blogUrl" to={node.frontmatter.templateKey +'/'+ node.frontmatter.slug}>
{node.frontmatter.title} {node.frontmatter.author}
</Link>
<div>{node.excerpt}</div>
</div>
))}


<br />


<ul className="pagination">
{
    generateSmartLinks(3,index,pageCount).map((item,key)=>{
        return <li>{item}</li>
    })
}
</ul>

<br />
<ul className="pagination">
<li><PageLink active={first} url={'articles/'+previousUrl} className="button small" text="Prev" /></li>

{Array.from({ length: pageCount }, (_, i) => (
    <li key={`pagination-number${i + 1}`}>
      <PageLink active={index-1===i ? 1 : 0}  url={`articles/${i === 0 ? '' : i + 1}`}  className="page" text={i + 1}/>
    </li>
))}

<li><PageLink active={last} url={'articles/'+nextUrl} className="button small" text="Next" /></li>
</ul>


                        </div>
                    </section>
                </div>

            </Layout>
        )
    //}
}
/*
export const pageQuery = graphql`
    query ArticlesIndexQuery {
        allMarkdownRemark (
            
            filter: { frontmatter: { published: { eq: true } } }
            sort: { fields: [frontmatter___date], order: DESC }
        ){
            edges {
              node {
                id
                frontmatter {
                  slug
                  templateKey
                  title
                  date(formatString: "MMMM DD, YYYY")
                  author
                  published
                }
                excerpt
              }
            }
        }
    }
`
*/
export default ArticlesIndex