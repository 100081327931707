import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from "gatsby"
//import Img from "gatsby-image"

const Header = (props) => {
    /*
    const data = useStaticQuery(graphql`
    {
        fileName: file(relativePath: { eq: "ds-logo.gif" }) {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid
              }
            }
        }
    }
    `)
    */
    
    return (
    <header id="header" className="alt">
        <a href="http://www.dailyspikes.com" className="logo">
        <img className="dsLogo" src="/img/ds-logo.gif"  />
        </a>
        <nav>
            <a className="menu-link" onClick={props.onToggleMenu} href="javascript:;">Menu</a>
        </nav>
        
        {/*<Img fluid={data.fileName.childImageSharp.fluid} alt="" />*/}
    </header>
    )
}

Header.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Header
