import React from 'react'
import CurrentYear from './Utils'
const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <ul className="icons">
                <li><a href="#" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>
                <li><a href="#" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                <li><a href="#" className="icon alt fa-pinterest"><span className="label">Pinterest</span></a></li>
                
            </ul>
            <ul className="copyright">
                <li>&copy; Historophile <CurrentYear /></li>
            </ul>
        </div>
    </footer>
)

export default Footer
